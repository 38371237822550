import React, { useCallback, useState } from "react";
import { Link } from "gatsby";
import { API } from "aws-amplify";
import { useForm } from "react-hook-form";

import { useSiteMetadata } from "../hooks/SiteMetadataQuery";

import { Layout } from "../components/Layout";
import {
  Form,
  Label,
  InputError,
  InputTextBox,
  InputRow,
  InputTextArea,
  SubmitError,
  SubmitSuccess,
  SubmitButton
} from "../components/Forms";
import { PageSeo } from "../components/Seo";
import { Copy } from "../components/Copy";
import { Container, Heading } from "../components/Page";

const ContactPage = () => {
  const { getAQuoteUrl } = useSiteMetadata();
  const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm();
  const [result, setResult] = useState();
  const onSubmit = useCallback((data) => {
    setResult("LOADING");
    API.post("kghillustrates", "/contact-form", { body: data })
      .then(() => {
        reset();
        setResult("SUCCESS");
      })
      .catch(() => {
        setResult("FAILED");
      });
  }, [reset]);

  return (
    <Layout>
      <PageSeo tabTitleSegments={["Contact"]} title="Contact" />
      <Container className="max-w-narrow">
          <Heading>Contact</Heading>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Copy className="-mt-5 mb-10">
              <ul>
                <li>For wedding enquires fill in the quote form <a rel="noreferrer" target="_blank" href={getAQuoteUrl}>here</a></li>
                <li>For portrait enquires fill in the form on the <Link to="/portraits#form">portraits page</Link></li>
                <li>For anything else use the form below</li>
              </ul>
            </Copy>
            <InputRow>
              <Label label="Name" required>
                <InputTextBox {...register("name", { required: true })} />
              </Label>
              {errors.name && <InputError>Please enter your name</InputError>}
            </InputRow>
            <InputRow>
              <Label label="Email" required>
                <InputTextBox {...register("email", { required: true })} />
              </Label>
              {errors.email && <InputError>Please enter your email</InputError>}
            </InputRow>
            <InputRow>
              <Label label="Telephone" required>
                <InputTextBox {...register("telephone", { required: true })} />
              </Label>
              {errors.telephone && <InputError>Please enter your telephone number</InputError>}
            </InputRow>
            <InputRow>
              <Label label="Message" required>
                <InputTextArea {...register("message", { required: true })} />
              </Label>
              {errors.message && <InputError>Please enter your message</InputError>}
            </InputRow>
            {result === "FAILED" && <SubmitError>Something went wrong please try again</SubmitError>}
            {!isDirty && result === "SUCCESS" && <SubmitSuccess>Thank you for your query</SubmitSuccess>}
            <SubmitButton loading={result === "LOADING"}>Send</SubmitButton>
          </Form>
      </Container>
    </Layout>
  );
};

export default ContactPage;
